var __jsx = React.createElement;
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import Head from 'next/head';
import Router from 'next/router';
import React from 'react';
import { AuthProvider, useAuthentication } from '../hooks/useAuthentication';
import { cancelAllRequest, setUrl } from '../helpers/browser';
import useQueryParams from '../hooks/useQueryParams';
import useSEO from '../hooks/useSEO';
import theme from '../themes/default';

var Application = function Application(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps;
  var params = useQueryParams();
  var seo = useSEO(pageProps);
  React.useEffect(function () {
    setUrl('override_referrer', null);
    setUrl('stripe_success', null);
    var jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  return __jsx(AuthProvider, {
    stripeSuccess: params.stripe_success
  }, __jsx(Head, null, __jsx("meta", {
    charSet: "utf-8",
    key: "charSet"
  }), __jsx("meta", {
    name: "viewport",
    content: "minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
  }), __jsx("meta", {
    name: "robots",
    content: "index, follow"
  }), __jsx("meta", {
    property: "og:locale",
    content: "en_US"
  }), __jsx("meta", {
    property: "og:type",
    content: "website"
  }), __jsx("meta", {
    property: "og:site_name",
    content: "WOXO"
  }), __jsx("link", {
    rel: "shortcut icon",
    href: "/static/favicon.ico"
  }), __jsx("title", null, seo.title), __jsx("link", {
    rel: "canonical",
    href: seo.canonical
  }), __jsx("meta", {
    name: "description",
    content: seo.description
  }), __jsx("meta", {
    name: "keywords",
    content: seo.keywords
  }), __jsx("meta", {
    property: "og:title",
    content: seo['og:title']
  }), __jsx("meta", {
    property: "og:url",
    content: seo['og:url']
  }), __jsx("meta", {
    property: "og:description",
    content: seo['og:description']
  }), __jsx("meta", {
    property: "og:image",
    content: seo['og:image']
  }), __jsx("meta", {
    property: "og:image:width",
    content: seo['og:image:width']
  }), __jsx("meta", {
    property: "og:image:height",
    content: seo['og:image:height']
  }), __jsx("meta", {
    name: "twitter:card",
    content: seo['twitter:card']
  }), __jsx("meta", {
    name: "twitter:title",
    content: seo['twitter:title']
  }), __jsx("meta", {
    property: "twitter:url",
    content: seo['twitter:url']
  }), __jsx("meta", {
    name: "twitter:description",
    content: seo['twitter:description']
  }), __jsx("meta", {
    name: "twitter:image",
    content: seo && seo['twitter:image']
  }), __jsx("meta", {
    name: "theme-color",
    content: theme.palette.primary.main
  }), __jsx("script", {
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML: {
      __html: "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n          })(window,document,'script','dataLayer','GTM-NMP7265');"
    }
  })), __jsx(ThemeProvider, {
    theme: theme
  }, __jsx(ConfirmProvider, null, __jsx(CssBaseline, null), __jsx(Component, pageProps))));
};

Application.defaultProps = {
  pageProps: {}
};

Router.onRouteChangeStart = function (url) {
  cancelAllRequest();
};

export default Application;